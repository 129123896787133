import React from 'react';
import { Container } from 'react-bootstrap';
import ParticlesContainer from '../components/ParticlesContainer';

export class Home extends React.Component {
	render() {
		return (
			<section id="home" className="home d-flex align-items-center">
				<ParticlesContainer />
				<Container>
					<div className="intro">
						<span
							style={{
								fontSize: '60px',
								fontWeight: 500,
								fontFamily: "'Abril Fatface', cursive",
								lineHeight: '36px',
								background: '-webkit-linear-gradient(45deg, #71CDFF, #E100B5 80%)',
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent'
							}}
							className="breathe"
						>
							11.01.2021
						</span>
					</div>
				</Container>
			</section>
		);
	}
}

export default Home;
