import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import details from '../data/details.json';

export class Header extends React.Component {
	render() {
		return (
			<header className="kd-header fixed-top">
				<Container>
					<Navbar expand="lg" variant="dark">
						<Navbar.Brand href="#">
							<div className="text-center">
								<img src={details.logo} alt="Art Cabinet" style={{ width: '280px' }} />
							</div>
						</Navbar.Brand>
					</Navbar>
				</Container>
			</header>
		);
	}
}

export default Header;
