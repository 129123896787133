import React from 'react';
import { Header, Home } from '../sections';

import '../components/Icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
	return (
		<>
			<Header />
			<main>
				<Home />
			</main>
		</>
	);
}

export default App;
